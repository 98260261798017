import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import SquareAnimationNoMargin from 'components/squareAnimationNoMargin/squareAnimationNoMargin';
import SubscriptionSvg from 'components/subscriptionSvg/subscriptionSvg';
import CirclePurpleRightSlow from 'components/circle/circlePurpleRightSlow';
import CirclePurpleRight from 'components/circle/circlePurpleRight';
import {
  SectionWrapper,
  SquareWrapper,
  TitleWrapper,
  TextWrapper,
  TextWord,
  SvgWrapper,
  FirstCircleWrapper,
  SecondCircleWrapper,
} from './subscription.styled';

const Subscription = () => {
  const textRef = createRef();

  const tl = gsap.timeline();

  useEffect(() => {
    tl.staggerFrom(
      textRef.current.children,
      0.5,
      { opacity: 0, x: -20, y: -20 },
      0.25
    );
  });

  return (
    <SectionWrapper>
      <TitleWrapper>
        <SquareWrapper>
          <SquareAnimationNoMargin />
        </SquareWrapper>
        <TextWrapper ref={textRef}>
          <TextWord>Specjalizujemy&nbsp;</TextWord>
          <TextWord>się&nbsp;</TextWord>
          <TextWord>w&nbsp;</TextWord>
          <TextWord>obsłudze&nbsp;</TextWord>
          <TextWord>graficznej&nbsp;</TextWord>
          <TextWord>obejmującej&nbsp;</TextWord>
          <TextWord>projekty&nbsp;</TextWord>
          <TextWord>związane&nbsp;</TextWord>
          <TextWord>z&nbsp;</TextWord>
          <TextWord>komunikatami&nbsp;</TextWord>
          <TextWord>wizualnymi&nbsp;</TextWord>
          <TextWord>i&nbsp;</TextWord>
          <TextWord>tekstowymi&nbsp;</TextWord>
          <TextWord>-&nbsp;</TextWord>
          <TextWord>sprawdź&nbsp;</TextWord>
          <TextWord>naszą&nbsp;</TextWord>
          <TextWord>ofertę:&nbsp;</TextWord>
        </TextWrapper>
      </TitleWrapper>
      <SvgWrapper>
        <SubscriptionSvg />
        <FirstCircleWrapper>
          <CirclePurpleRightSlow />
        </FirstCircleWrapper>
        <SecondCircleWrapper>
          <CirclePurpleRight />
        </SecondCircleWrapper>
      </SvgWrapper>
    </SectionWrapper>
  );
};

export default Subscription;
