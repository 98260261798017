import styled from 'styled-components';

export const ContentWrapperMobile = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: none;
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    display: flex;
  }
`;
