import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-flow: column;

  @media (min-width: 850px) {
    flex-flow: row;
  }
`;

export const SquareWrapper = styled.div`
  padding: 0 15px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 100px;

  @media (min-width: 850px) {
    padding: 0;
  }
`;

export const TitleWrapper = styled.div`
  padding: 0 15px;
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    margin-left: 50px;
    width: calc(50% - 50px);
    height: auto;
  }
`;

export const TextWrapper = styled.p`
  width: 100%;
  height: auto;
  display: block;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.font.s};

  @media (min-width: 850px) {
    text-align: left;
  }
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const SvgWrapper = styled.span`
  width: 100%;
  height: 300px;
  position: relative;

  @media (min-width: 850px) {
    width: 50%;
    height: 430px;
    position: relative;
  }
`;

export const FirstCircleWrapper = styled.div`
  position: absolute;
  width: 250px;
  top: 270px;
  right: 40px;

  @media (min-width: 850px) {
    top: 340px;
    right: 65px;
  }

  @media (min-width: 1100px) {
    top: 380px;
    right: 75px;
  }
`;

export const SecondCircleWrapper = styled.div`
  position: absolute;
  width: 100px;
  top: 95px;
  right: -60px;

  @media (min-width: 850px) {
    width: 120px;
    top: 135px;
    right: -60px;
  }

  @media (min-width: 1100px) {
    top: 185px;
    right: -50px;
  }
`;
