import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { Svg } from './subscriptionSvg.styled';

const SubscriptionSvg = () => {
  const firstCircleRef = createRef();
  const secondCircleRef = createRef();

  const firstTextRef = createRef();
  const secondTextRef = createRef();
  const thirdTextRef = createRef();

  const firstCircleTl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

  const secondCircleTl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

  const priceTl = gsap.timeline({ repeat: -1, repeatDelay: 0, yoyo: true });

  useEffect(() => {
    firstCircleTl.to(firstCircleRef.current, 20, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
    secondCircleTl.to(
      secondCircleRef.current,
      50,
      {
        rotation: 360,
        transformOrigin: 'center center',
        ease: 'none',
      },
      'circleRotate'
    );
    priceTl
      .to(
        firstTextRef.current,
        1,
        { y: 10, x: 20, transformOrigin: 'center center', ease: 'none' },
        'priceStart'
      )
      .to(secondTextRef.current, 1, {
        y: 5,
        x: 20,
        transformOrigin: 'center center',
        ease: 'none',
      })
      .to(thirdTextRef.current, 1, {
        y: -10,
        x: 20,
        transformOrigin: 'center center',
        ease: 'none',
      });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 450.6 367"
    >
      <line
        id="linia_do_kolo_2"
        className="st0"
        x1="289.7"
        y1="335.8"
        x2="294.8"
        y2="228.4"
      />
      <line
        id="linia_do_kolo_1"
        className="st0"
        x1="421.5"
        y1="187.9"
        x2="330.3"
        y2="192.9"
      />
      <line
        id="linia_S"
        className="st0"
        x1="214"
        y1="104.5"
        x2="267.7"
        y2="154.3"
      />
      <line
        id="linia_M"
        className="st0"
        x1="92.1"
        y1="183.5"
        x2="244.5"
        y2="201.6"
      />
      <line
        id="Linia_L"
        className="st0"
        x1="119.2"
        y1="288.8"
        x2="258"
        y2="239.6"
      />
      <g ref={firstTextRef}>
        <circle className="st1" cx="211.2" cy="101.9" r="46.7" />
        <g>
          <rect x="172.8" y="83.9" className="st2" width="76.6" height="41" />
          <text transform="matrix(1 0 0 1 179.3243 95.725)">
            <tspan x="0" y="0" className="st3 st4 st5">
              1-5 sztuk
            </tspan>
            <tspan x="4.4" y="19.2" className="st3 st4 st5">
              500 pln
            </tspan>
          </text>
        </g>
        <path
          id="S_1_"
          className="st6"
          d="M182.3,28.9c-12.1,0-21.9,9.8-21.9,21.9c0,12.1,9.8,21.9,21.9,21.9c12.1,0,21.9-9.8,21.9-21.9
    C204.2,38.6,194.4,28.9,182.3,28.9z M187.6,58.6c-1.1,1-2.5,1.6-4.3,1.6c-1.4,0-2.6-0.3-3.6-1c-1-0.7-1.9-1.7-2.6-3.2l3.1-1.9
    c0.9,1.7,2,2.6,3.2,2.6c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.8,0.6-1.3c0-0.4-0.2-0.9-0.5-1.3c-0.3-0.4-1-1.1-2.2-2
    c-2.1-1.7-3.5-3.1-4.1-4c-0.6-0.9-0.9-1.9-0.9-2.8c0-1.3,0.5-2.5,1.5-3.5c1-1,2.3-1.4,3.8-1.4c1,0,1.9,0.2,2.8,0.7
    c0.9,0.4,1.8,1.3,2.8,2.5l-2.7,2.4c-1-1.3-1.9-2-2.9-2c-0.5,0-0.9,0.1-1.2,0.4c-0.3,0.3-0.5,0.6-0.5,0.9c0,0.3,0.1,0.6,0.3,0.9
    c0.3,0.4,1.2,1.2,2.7,2.5c1.4,1.2,2.3,1.9,2.6,2.2c0.7,0.8,1.3,1.5,1.6,2.2c0.3,0.7,0.5,1.4,0.5,2.3
    C189.2,56.2,188.7,57.5,187.6,58.6z"
        />
      </g>
      <g ref={secondTextRef}>
        <circle className="st1" cx="113.7" cy="189.2" r="46.7" />
        <g>
          <rect x="75.3" y="171.2" className="st2" width="76.6" height="41" />
          <text transform="matrix(1 0 0 1 77.402 182.9593)">
            <tspan x="0" y="0" className="st3 st4 st5">
              1-10 sztuk
            </tspan>
            <tspan x="4.4" y="19.2" className="st3 st4 st5">
              1000 pln
            </tspan>
          </text>
        </g>
        <path
          id="M"
          className="st6"
          d="M75.3,120.6c-12.1,0-21.9,9.8-21.9,21.9c0,12.1,9.8,21.9,21.9,21.9s21.9-9.8,21.9-21.9
    C97.2,130.4,87.4,120.6,75.3,120.6z M84.2,151.1l-2.1-12.2L78,151.1h-3.3l-4.1-12.2l-2.1,12.2H65l3.4-19.4h3.6l4.5,13.5l4.5-13.5
    h3.6l3.3,19.4H84.2z"
        />
      </g>
      <g ref={thirdTextRef}>
        <circle className="st1" cx="130.7" cy="296.9" r="46.7" />
        <rect x="92.4" y="278.9" className="st2" width="76.6" height="41" />
        <text transform="matrix(1 0 0 1 94.4775 290.7185)">
          <tspan x="0" y="0" className="st3 st4 st5">
            1-15 sztuk
          </tspan>
          <tspan x="4.4" y="19.2" className="st3 st4 st5">
            1500 pln
          </tspan>
        </text>
        <path
          id="L"
          className="st6"
          d="M71.8,297.5c-12.1,0-21.9,9.8-21.9,21.9c0,12.1,9.8,21.9,21.9,21.9c12.1,0,21.9-9.8,21.9-21.9
    C93.6,307.3,83.8,297.5,71.8,297.5z M78.4,327.8h-9.1v-19.4H73v15.9h5.4V327.8z"
        />
      </g>
      <path
        id="zembatka_2"
        ref={secondCircleRef}
        className="st1"
        d="M278.2,322.8C145.7,322.8,38,430.5,38,562.9c0,132.4,107.7,240.2,240.2,240.2
    c132.4,0,240.2-107.7,240.2-240.2C518.3,430.5,410.6,322.8,278.2,322.8z M467.3,437.2l19.8,38l0,0l-14,7.3c0.6,1.6,1.2,3.2,1.8,4.8
    c6,16.6,9.9,33.7,11.6,50.8l12.8-0.4l1.3,42.9l-14.3,0.4c-1.5,13.2-4.2,26.3-8.2,39.1l18.5,7.8L480,667.4l-18.9-7.9
    c-9.3,16.7-20.7,31.9-34,45.3l12.1,14.6l-33,27.4l-12.4-14.9c-13.3,8.7-27.8,16-43.1,21.6c-2.7,1-5.4,1.9-8.1,2.7l4.7,21.8l-5.1,1.8
    l-36.7,7.8l-4.9-22.7c-18,1.9-36.1,1.3-53.9-1.6l-4.8,19.4l-41.6-10.2l5-20.3c-4.5-1.7-9-3.6-13.5-5.7c-11.7-5.5-22.8-12-33.1-19.3
    l-14.3,15.5l-31.5-29.1l13.7-14.9c-12.7-13.9-23.6-29.5-32.2-46.6l-20.9,7.5l-14.4-40.4l20-7.2c-4.9-18-7.3-36.4-7.2-54.7l-16.4-1.8
    l4.7-42.6l16.3,1.8c3.3-15.1,8.2-29.9,14.9-44.3c5.6-12.1,12.4-23.5,20-34.1L100.1,426l28.9-31.7l11.2,10.2
    c14.5-13.2,30.9-24.2,48.8-32.9l-6.2-16.9l40.3-14.7l6.1,16.9c18.4-4.7,37.2-6.7,55.9-6.3l1.5-14.7l42.6,4.4l-1.6,15.8
    c13.6,3.2,27,7.9,39.9,13.9c3.6,1.7,7.1,3.4,10.5,5.3l7.4-10.7l35.2,24.4l-7.1,10.3c15.4,12.9,28.8,28.1,39.9,45L467.3,437.2z"
      />
      <path
        id="zembatka_1"
        ref={firstCircleRef}
        className="st1"
        d="M525,94.8c-57.6,0-104.4,46.8-104.4,104.4c0,57.6,46.8,104.4,104.4,104.4
    c57.6,0,104.4-46.8,104.4-104.4C629.4,141.6,582.6,94.8,525,94.8z M607.2,144.6l8.6,16.5l0,0l-6.1,3.2c0.3,0.7,0.5,1.4,0.8,2.1
    c2.6,7.2,4.3,14.6,5,22.1l5.6-0.2l0.5,18.6l-6.2,0.2c-0.6,5.7-1.8,11.4-3.6,17l8,3.4l-7.2,17.2l-8.2-3.4c-4,7.3-9,13.9-14.8,19.7
    l5.3,6.3l-14.3,11.9l-5.4-6.5c-5.8,3.8-12.1,6.9-18.7,9.4c-1.2,0.4-2.3,0.8-3.5,1.2l2,9.5l-2.2,0.8l-16,3.4l-2.1-9.9
    c-7.8,0.8-15.7,0.6-23.4-0.7l-2.1,8.4l-18.1-4.5l2.2-8.8c-2-0.8-3.9-1.6-5.8-2.5c-5.1-2.4-9.9-5.2-14.4-8.4l-6.2,6.8l-13.7-12.6
    l6-6.5c-5.5-6-10.2-12.8-14-20.3l-9.1,3.3l-6.3-17.6l8.7-3.1c-2.1-7.8-3.2-15.8-3.1-23.8l-7.1-0.8l2-18.5l7.1,0.8
    c1.4-6.6,3.6-13,6.5-19.3c2.5-5.3,5.4-10.2,8.7-14.8l-5-4.5l12.5-13.8l4.9,4.4c6.3-5.7,13.4-10.5,21.2-14.3l-2.7-7.4l17.5-6.4
    l2.7,7.3c8-2,16.2-2.9,24.3-2.7l0.7-6.4l18.5,1.9l-0.7,6.9c5.9,1.4,11.7,3.4,17.4,6.1c1.6,0.7,3.1,1.5,4.6,2.3l3.2-4.6l15.3,10.6
    l-3.1,4.5c6.7,5.6,12.5,12.2,17.3,19.5L607.2,144.6z"
      />
      <g id="Abonament">
        <circle
          id="kolo_abonamentu"
          className="st7"
          cx="290.6"
          cy="206.2"
          r="59.9"
        />
        <g id="abonament">
          <g>
            <path
              className="st8"
              d="M248.3,192.6l2.1,0l4.3,11.1l-2.2,0l-0.9-2.3l-4.5,0l-0.9,2.3l-2.2,0L248.3,192.6z M249.4,195.5l-1.5,3.8
          l3,0L249.4,195.5z"
            />
            <path
              className="st8"
              d="M256.4,203.7l0-11.1l1.7,0c1,0,1.8,0.1,2.2,0.2c0.7,0.2,1.2,0.5,1.6,1c0.4,0.5,0.6,1,0.6,1.7
          c0,0.4-0.1,0.8-0.3,1.2c-0.2,0.3-0.5,0.7-0.9,1c0.7,0.3,1.2,0.7,1.5,1.2c0.3,0.5,0.5,1,0.5,1.7c0,0.6-0.2,1.2-0.5,1.7
          c-0.3,0.5-0.7,0.9-1.2,1.2c-0.5,0.3-1.2,0.4-2.1,0.4L256.4,203.7z M258.5,194.6l0,2.3l0.5,0c0.5,0,0.9-0.1,1.1-0.3
          c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.3-1.1-0.3L258.5,194.6z M258.5,198.8l0,2.9l0.5,0
          c0.9,0,1.5-0.1,1.8-0.3c0.3-0.2,0.5-0.5,0.5-1c0-0.5-0.2-0.9-0.5-1.1c-0.4-0.3-1-0.4-1.8-0.4L258.5,198.8z"
            />
            <path
              className="st8"
              d="M270.6,192.3c1.6,0,2.9,0.6,4.1,1.7c1.1,1.1,1.7,2.5,1.7,4.2c0,1.6-0.6,3-1.7,4.1c-1.1,1.1-2.5,1.7-4.1,1.7
          c-1.7,0-3.1-0.6-4.2-1.7c-1.1-1.2-1.7-2.5-1.7-4.1c0-1.1,0.3-2,0.8-2.9c0.5-0.9,1.2-1.6,2.1-2.1
          C268.6,192.5,269.6,192.3,270.6,192.3z M270.6,194.3c-1,0-1.9,0.4-2.6,1.1c-0.7,0.7-1.1,1.6-1,2.7c0,1.2,0.4,2.2,1.3,2.9
          c0.7,0.6,1.5,0.8,2.4,0.8c1,0,1.9-0.4,2.6-1.1c0.7-0.7,1.1-1.6,1.1-2.7c0-1.1-0.4-2-1.1-2.7C272.5,194.7,271.6,194.3,270.6,194.3
          z"
            />
            <path
              className="st8"
              d="M278.4,192.5l2,0l4.8,7.3l0-7.3l2.1,0l0,11.1l-2,0l-4.8-7.3l0,7.3l-2.1,0L278.4,192.5z"
            />
            <path
              className="st8"
              d="M293.2,192.5l2.1,0l4.3,11.1l-2.2,0l-0.9-2.3l-4.5,0l-0.9,2.3l-2.2,0L293.2,192.5z M294.3,195.5l-1.5,3.8
          l3,0L294.3,195.5z"
            />
            <path
              className="st8"
              d="M302.4,192.5l2.1,0l2.6,7.8l2.6-7.8l2.1,0l1.9,11.1l-2,0l-1.2-7l-2.4,7l-1.9,0l-2.4-7l-1.2,7l-2.1,0
          L302.4,192.5z"
            />
            <path
              className="st8"
              d="M315.2,192.5l6.1,0l0,2.1l-4,0l0,2l4,0l0,2l-4,0l0,2.9l4,0l0,2.1l-6.1,0L315.2,192.5z"
            />
            <path
              className="st8"
              d="M323.3,192.5l2,0l4.8,7.3l0-7.3l2.1,0l0,11.1l-2,0l-4.8-7.3l0,7.3l-2.1,0L323.3,192.5z"
            />
            <path
              className="st8"
              d="M333.7,192.5l6.1,0l0,2.1l-2,0l0,9l-2.1,0l0-9l-2,0L333.7,192.5z"
            />
            <path
              className="st8"
              d="M260.3,213l-1.5,1.5c-0.9-1-2-1.5-3.3-1.5c-1.1,0-2,0.4-2.8,1.1c-0.7,0.7-1.1,1.6-1.1,2.6
          c0,1.1,0.4,2,1.2,2.7c0.8,0.7,1.7,1.1,2.8,1.1c0.7,0,1.3-0.2,1.9-0.5c0.5-0.3,0.9-0.8,1.2-1.4l-3.2,0l0-2l5.6,0l0,0.5
          c0,1-0.2,1.9-0.8,2.8c-0.5,0.9-1.2,1.5-2,2c-0.8,0.5-1.7,0.7-2.8,0.7c-1.2,0-2.2-0.2-3.1-0.7c-0.9-0.5-1.6-1.2-2.2-2.1
          c-0.5-0.9-0.8-1.9-0.8-3c0-1.5,0.5-2.8,1.5-3.8c1.2-1.3,2.7-1.9,4.5-2c1,0,1.9,0.2,2.7,0.5C258.9,211.7,259.6,212.2,260.3,213z"
            />
            <path
              className="st8"
              d="M263.2,211.1l2.2,0c1.2,0,2.1,0.1,2.6,0.3c0.5,0.2,0.9,0.6,1.3,1.1s0.5,1.1,0.5,1.8c0,0.7-0.2,1.3-0.5,1.8
          c-0.3,0.5-0.9,0.9-1.6,1.1l2.6,4.9l-2.3,0l-2.5-4.7l-0.2,0l0,4.7l-2.1,0L263.2,211.1z M265.3,215.5l0.7,0c0.7,0,1.1-0.1,1.4-0.3
          c0.3-0.2,0.4-0.5,0.4-0.9c0-0.2-0.1-0.5-0.2-0.6c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.6-0.1-1.2-0.1l-0.6,0L265.3,215.5z"
            />
            <path
              className="st8"
              d="M275.4,211.1l2.1,0l4.3,11.1l-2.2,0l-0.9-2.3l-4.5,0l-0.9,2.3l-2.2,0L275.4,211.1z M276.5,214.1l-1.5,3.8
          l3,0L276.5,214.1z"
            />
            <path
              className="st8"
              d="M283.5,211.1l5.5,0l0,2.1l-3.4,0l0,2l3.4,0l0,2l-3.4,0l0,5l-2.1,0L283.5,211.1z"
            />
            <path
              className="st8"
              d="M290.8,211.1l2.1,0l0,11.1l-2.1,0L290.8,211.1z"
            />
            <path
              className="st8"
              d="M305.5,213l-1.5,1.4c-1-1.1-2.1-1.6-3.4-1.6c-1.1,0-2,0.4-2.7,1.1c-0.7,0.7-1.1,1.6-1.1,2.7
          c0,0.7,0.2,1.4,0.5,2c0.3,0.6,0.8,1,1.4,1.4c0.6,0.3,1.2,0.5,2,0.5c0.6,0,1.2-0.1,1.7-0.3s1.1-0.6,1.7-1.3l1.4,1.5
          c-0.8,0.8-1.6,1.4-2.3,1.7c-0.7,0.3-1.6,0.5-2.5,0.5c-1.7,0-3.1-0.5-4.2-1.6c-1.1-1.1-1.7-2.5-1.7-4.2c0-1.1,0.2-2.1,0.7-3
          c0.5-0.9,1.2-1.6,2.2-2.1s1.9-0.8,3-0.8c0.9,0,1.8,0.2,2.7,0.6S304.9,212.3,305.5,213z"
            />
            <path
              className="st8"
              d="M306.8,211.1l6.7,0l-3.9,9.1l3.7,0l0,2l-6.8,0l3.9-9l-3.6,0L306.8,211.1z"
            />
            <path
              className="st8"
              d="M315.1,211.1l2,0l4.8,7.3l0-7.3l2.1,0l0,11.1l-2,0l-4.8-7.3l0,7.3l-2.1,0L315.1,211.1z"
            />
            <path
              className="st8"
              d="M325.8,211.1l2.3,0l2.1,4l2.1-4l2.2,0l-3.2,6.3l0,4.8l-2.1,0l0-4.8L325.8,211.1z"
            />
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default SubscriptionSvg;
