import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollScene } from 'scrollscene';
import { graphql, useStaticQuery } from 'gatsby';
import FirstOfferCircle from 'components/offerCircle/firstOfferCircle';
import SecondOfferCircle from 'components/offerCircle/secondOfferCircle';
import ThirdOfferCircle from 'components/offerCircle/thirdOfferCircle';
import FourthOfferCircle from 'components/offerCircle/fourthOfferCircle';
import CirclePurpleRightFast from 'components/circle/circlePurpleRightFast';
import CirclePurpleRightSlow from 'components/circle/circlePurpleRightSlow';
import CircleRightFast from 'components/circle/circleRightFast';
import CircleRightSlow from 'components/circle/circleRightSlow';
import {
  SectionWrapper,
  BackgroundWrapper,
  ContentWrapper,
  LineWrapper,
  HorizontalLineWrapper,
  TextWrapper,
  TitleWrapper,
  TextBoxWrapper,
  ListWrapper,
  TextContainer,
  TextBox,
  TextWord,
  LineDot,
  Dot,
  FirstBlueLine,
  SecondBlueLine,
  ThirdBlueLine,
  FirstWhiteLine,
  SecondWhiteLine,
  FirstHorizonstalLine,
  SecondHorizontalLine,
  FirstImage,
  FirstCircleWrapper,
  SecondCircleWrapper,
  ThirdCircleWrapper,
  FourthCircleWrapper,
  FifthCircleWrapper,
  SixthCircleWrapper,
  SeventhCircleWrapper,
  EighthCircleWrapper,
} from './offerDesktop.styled';

const OfferDesktop = () => {
  const {
    background,
    firstImage,
    secondImage,
    thirdImage,
  } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstImage: file(relativePath: { eq: "offerFirst.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      secondImage: file(relativePath: { eq: "offerSecond.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thirdImage: file(relativePath: { eq: "offerThird.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const firstLineSectionRef = createRef();
  const secondLineSectionRef = createRef();
  const thirdLineSectionRef = createRef();

  const firstHorizontalLineSectionRef = createRef();
  const secondHorizontalLineSectionRef = createRef();
  const thirdHorizontalLineSectionRef = createRef();

  const firstTitleRef = createRef();
  const secondTitleRef = createRef();
  const thirdTitleRef = createRef();

  const firstTextRef = createRef();
  const secondTextRef = createRef();
  const thirdTextRef = createRef();

  const firstListRef = createRef();
  const secondListRef = createRef();
  const thirdListRef = createRef();

  const tl = gsap.timeline();

  const firstSectionTl = gsap.timeline();

  const secondSectionTl = gsap.timeline({ paused: true });

  const thirdSectionTl = gsap.timeline({ paused: true });

  useEffect(() => {
    tl.set(
      [
        firstLineSectionRef.current.children[0],
        firstLineSectionRef.current.children[1],
        firstLineSectionRef.current.children[2],
        firstLineSectionRef.current.children[3],
        secondLineSectionRef.current.children[0],
        secondLineSectionRef.current.children[1],
        thirdLineSectionRef.current.children[0],
        firstHorizontalLineSectionRef.current.children[0],
        firstHorizontalLineSectionRef.current.children[1],
        firstHorizontalLineSectionRef.current.children[2],
        secondHorizontalLineSectionRef.current.children[0],
        secondHorizontalLineSectionRef.current.children[1],
        secondHorizontalLineSectionRef.current.children[2],
        thirdHorizontalLineSectionRef.current.children[0],
        thirdHorizontalLineSectionRef.current.children[1],
        thirdHorizontalLineSectionRef.current.children[2],
        firstTitleRef.current,
        secondTitleRef.current,
        thirdTitleRef.current,
        firstTextRef.current,
        secondTextRef.current,
        thirdTextRef.current,
        firstListRef.current,
        secondListRef.current,
        thirdListRef.current,
      ],
      { opacity: 0 }
    );
    firstSectionTl
      .fromTo(
        firstLineSectionRef.current.children[0],
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .fromTo(
        firstLineSectionRef.current.children[1],
        0.3,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        firstLineSectionRef.current.children[2],
        0.15,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .addLabel('horizontalStart')
      .fromTo(
        firstLineSectionRef.current.children[3],
        0.45,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        firstHorizontalLineSectionRef.current.children[0],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart'
      )
      .fromTo(
        firstHorizontalLineSectionRef.current.children[1],
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center left' },
        { scale: 1, transformOrigin: 'center left' },
        'horizontalStart+=0.1'
      )
      .addLabel('textStart')
      .fromTo(
        firstHorizontalLineSectionRef.current.children[2],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart+=0.35'
      )
      .set(
        [firstTitleRef.current, firstTextRef.current, firstListRef.current],
        { opacity: 1 }
      )
      .staggerFrom(
        firstTitleRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        firstTextRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        firstListRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart+=2.5'
      );
    secondSectionTl
      .fromTo(
        secondLineSectionRef.current.children[0],
        0.15,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .addLabel('horizontalStart')
      .fromTo(
        secondLineSectionRef.current.children[1],
        0.45,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .fromTo(
        secondHorizontalLineSectionRef.current.children[0],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart'
      )
      .fromTo(
        secondHorizontalLineSectionRef.current.children[1],
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center left' },
        { scale: 1, transformOrigin: 'center left' },
        'horizontalStart+=0.1'
      )
      .addLabel('textStart')
      .fromTo(
        secondHorizontalLineSectionRef.current.children[2],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart+=0.35'
      )
      .set(
        [secondTitleRef.current, secondTextRef.current, secondListRef.current],
        { opacity: 1 }
      )
      .staggerFrom(
        secondTitleRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        secondTextRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        secondListRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart+=2.5'
      );
    thirdSectionTl
      .fromTo(
        thirdLineSectionRef.current.children[0],
        0.25,
        { opacity: 1, scaleY: 0, transformOrigin: 'top' },
        { scaleY: 1, transformOrigin: 'top', ease: 'none' }
      )
      .addLabel('horizontalStart')
      .fromTo(
        thirdHorizontalLineSectionRef.current.children[0],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart'
      )
      .fromTo(
        thirdHorizontalLineSectionRef.current.children[1],
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center left' },
        { scale: 1, transformOrigin: 'center left' },
        'horizontalStart+=0.1'
      )
      .addLabel('textStart')
      .fromTo(
        thirdHorizontalLineSectionRef.current.children[2],
        0.1,
        { opacity: 1, scaleX: 0, transformOrigin: 'left' },
        { scaleX: 1, transformOrigin: 'left', ease: 'none' },
        'horizontalStart+=0.35'
      )
      .set(
        [thirdTitleRef.current, thirdTextRef.current, thirdListRef.current],
        { opacity: 1 }
      )
      .staggerFrom(
        thirdTitleRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        thirdTextRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart'
      )
      .staggerFrom(
        thirdListRef.current.children,
        0.45,
        { opacity: 0, y: -20, x: -20 },
        0.15,
        'textStart+=2.5'
      );

    const firstScrollScene = new ScrollScene({
      triggerElement: secondHorizontalLineSectionRef.current.children[1],
      reverse: false,
      gsap: {
        timeline: secondSectionTl,
      },
    });

    const secondScrollScene = new ScrollScene({
      triggerElement: thirdHorizontalLineSectionRef.current.children[1],
      reverse: false,
      gsap: {
        timeline: thirdSectionTl,
      },
    });
  });

  return (
    <SectionWrapper>
      <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
        <ContentWrapper>
          <LineWrapper ref={firstLineSectionRef}>
            <LineDot />
            <FirstBlueLine />
            <FirstWhiteLine />
            <SecondWhiteLine />
          </LineWrapper>
          <TextWrapper>
            <TitleWrapper>
              <TextBox isWhite ref={firstTitleRef}>
                <TextWord>Usługi&nbsp;</TextWord>
                <TextWord>graficzne&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>animacje</TextWord>
              </TextBox>
            </TitleWrapper>
            <HorizontalLineWrapper ref={firstHorizontalLineSectionRef}>
              <FirstHorizonstalLine />
              <FirstImage fixed={firstImage.sharp.fixed} />
              <SecondHorizontalLine />
            </HorizontalLineWrapper>
            <TextBoxWrapper>
              <TextBox isWhite ref={firstTextRef}>
                <TextWord>W&nbsp;</TextWord>
                <TextWord>swojej&nbsp;</TextWord>
                <TextWord>pracy&nbsp;</TextWord>
                <TextWord>wykorzystujemy&nbsp;</TextWord>
                <TextWord>najnowsze&nbsp;</TextWord>
                <TextWord>trendy&nbsp;</TextWord>
                <TextWord>w&nbsp;</TextWord>
                <TextWord>projektowaniu&nbsp;</TextWord>
                <TextWord>graficznym&nbsp;</TextWord>
                <TextWord>co&nbsp;</TextWord>
                <TextWord>pozwala&nbsp;</TextWord>
                <TextWord>nam&nbsp;</TextWord>
                <TextWord>z&nbsp;</TextWord>
                <TextWord>łatwością&nbsp;</TextWord>
                <TextWord>dostosować&nbsp;</TextWord>
                <TextWord>się&nbsp;</TextWord>
                <TextWord>do&nbsp;</TextWord>
                <TextWord>każdego&nbsp;</TextWord>
                <TextWord>zadania.</TextWord>
              </TextBox>
            </TextBoxWrapper>
            <ListWrapper ref={firstListRef}>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  projektowanie logo
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  animacje
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  grafika komputerowa
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  prezentacje
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  grafika 2D i grafika 3D
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  plakaty
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  grafika rastrowa
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  identyfikacja wizualna
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  grafika wektorowa
                </TextBox>
              </TextContainer>
            </ListWrapper>
          </TextWrapper>
          <FirstCircleWrapper>
            <FirstOfferCircle />
          </FirstCircleWrapper>
          <SecondCircleWrapper>
            <SecondOfferCircle />
          </SecondCircleWrapper>
        </ContentWrapper>
      </BackgroundWrapper>
      <ContentWrapper>
        <LineWrapper ref={secondLineSectionRef}>
          <SecondBlueLine />
          <ThirdBlueLine />
        </LineWrapper>
        <TextWrapper>
          <TitleWrapper>
            <TextBox ref={secondTitleRef}>
              <TextWord>Social&nbsp;</TextWord>
              <TextWord>Media</TextWord>
            </TextBox>
          </TitleWrapper>
          <HorizontalLineWrapper ref={secondHorizontalLineSectionRef}>
            <FirstHorizonstalLine isBlue />
            <FirstImage fixed={secondImage.sharp.fixed} />
            <SecondHorizontalLine isBlue />
          </HorizontalLineWrapper>
          <TextBoxWrapper>
            <TextBox ref={secondTextRef}>
              <TextWord>Działalność&nbsp;</TextWord>
              <TextWord>w&nbsp;</TextWord>
              <TextWord>social&nbsp;</TextWord>
              <TextWord>mediach&nbsp;</TextWord>
              <TextWord>to&nbsp;</TextWord>
              <TextWord>podstawa&nbsp;</TextWord>
              <TextWord>-&nbsp;</TextWord>
              <TextWord>jeśli&nbsp;</TextWord>
              <TextWord>chcesz&nbsp;</TextWord>
              <TextWord>zaistnieć&nbsp;</TextWord>
              <TextWord>w&nbsp;</TextWord>
              <TextWord>sieci&nbsp;</TextWord>
              <TextWord>i&nbsp;</TextWord>
              <TextWord>zwiększyć&nbsp;</TextWord>
              <TextWord>liczbę&nbsp;</TextWord>
              <TextWord>swoich&nbsp;</TextWord>
              <TextWord>odbiorców&nbsp;</TextWord>
              <TextWord>to&nbsp;</TextWord>
              <TextWord>idealnie&nbsp;</TextWord>
              <TextWord>trafiłeś.</TextWord>
            </TextBox>
          </TextBoxWrapper>
          <ListWrapper ref={secondListRef}>
            <TextContainer>
              <Dot />
              <TextBox isMargin>
                dobór odpowiednich kanałów komunikacyjnych
              </TextBox>
            </TextContainer>
            <TextContainer>
              <Dot />
              <TextBox isMargin>monitorowanie i analizowanie mediów</TextBox>
            </TextContainer>
            <TextContainer>
              <Dot />
              <TextBox isMargin>
                reklama w portalach społecznościowych; tworzenie treści,
                publikacje
              </TextBox>
            </TextContainer>
          </ListWrapper>
        </TextWrapper>
        <ThirdCircleWrapper>
          <ThirdOfferCircle />
        </ThirdCircleWrapper>
        <FourthCircleWrapper>
          <CirclePurpleRightFast />
        </FourthCircleWrapper>
        <FifthCircleWrapper>
          <CirclePurpleRightSlow />
        </FifthCircleWrapper>
      </ContentWrapper>
      <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
        <ContentWrapper>
          <LineWrapper ref={thirdLineSectionRef}>
            <FirstWhiteLine isLonger />
          </LineWrapper>
          <TextWrapper>
            <TitleWrapper>
              <TextBox isWhite ref={thirdTitleRef}>
                <TextWord>Strony&nbsp;</TextWord>
                <TextWord>internetowe&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>sklepy&nbsp;</TextWord>
                <TextWord>internetowe</TextWord>
              </TextBox>
            </TitleWrapper>
            <HorizontalLineWrapper ref={thirdHorizontalLineSectionRef}>
              <FirstHorizonstalLine />
              <FirstImage fixed={thirdImage.sharp.fixed} />
              <SecondHorizontalLine />
            </HorizontalLineWrapper>
            <TextBoxWrapper>
              <TextBox isWhite ref={thirdTextRef}>
                <TextWord>Pomożemy&nbsp;</TextWord>
                <TextWord>stworzyć&nbsp;</TextWord>
                <TextWord>Ci&nbsp;</TextWord>
                <TextWord>unikatową&nbsp;</TextWord>
                <TextWord>stronę&nbsp;</TextWord>
                <TextWord>internetową&nbsp;</TextWord>
                <TextWord>-&nbsp;</TextWord>
                <TextWord>wyróżnij&nbsp;</TextWord>
                <TextWord>się&nbsp;</TextWord>
                <TextWord>wśród&nbsp;</TextWord>
                <TextWord>konkurencji&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>pozyskaj&nbsp;</TextWord>
                <TextWord>nowych&nbsp;</TextWord>
                <TextWord>klientów.</TextWord>
              </TextBox>
            </TextBoxWrapper>
            <ListWrapper isLast ref={thirdListRef}>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  tworzenie i poprawa stron www i sklepów internetowych
                </TextBox>
              </TextContainer>
              <TextContainer>
                <Dot isWhite />
                <TextBox isWhite isMargin>
                  zamieszczanie tekstów oraz aktualizacja treści
                </TextBox>
              </TextContainer>
            </ListWrapper>
          </TextWrapper>
          <SixthCircleWrapper>
            <FourthOfferCircle />
          </SixthCircleWrapper>
          <SeventhCircleWrapper>
            <CircleRightFast />
          </SeventhCircleWrapper>
          <EighthCircleWrapper>
            <CircleRightSlow />
          </EighthCircleWrapper>
        </ContentWrapper>
      </BackgroundWrapper>
    </SectionWrapper>
  );
};

export default OfferDesktop;
