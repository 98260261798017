import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const BackgroundWrapper = styled(BackgroundImage)`
  width: 100%;
  height: auto;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

export const LineWrapper = styled.div`
  width: 200px;
  height: auto;
  position: relative;
`;

export const HorizontalLineWrapper = styled.div`
  width: 100%;
  height: 30px;
  position: relative;
`;

export const TextWrapper = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  flex-flow: column;

  @media (min-width: 1200px) {
    width: calc(60% - 200px);
  }

  @media (min-width: 1350px) {
    width: 60%;
  }

  @media (min-width: 1600px) {
    width: calc(60% - 200px);
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding-left: 25px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TextBoxWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-left: 25px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const ListWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-left: 25px;
  margin-top: 30px;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  display: flex;
  flex-flow: ${props => (props.isLast ? 'column' : 'row')};
  flex-wrap: wrap;
  margin-bottom: ${props => (props.isLast ? '100px' : '0')};
`;

export const TextContainer = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
`;

export const TextBox = styled.p`
  width: 100%;
  height: auto;
  color: ${props => (props.isWhite ? `#ffffff` : `#7c7c7c`)};
  font-size: ${({ theme }) => theme.font.s};
  margin-left: ${props => (props.isMargin ? '5px' : '0')};
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const LineDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  left: 70px;
  top: -170px;

  @media (min-width: 1100px) {
    top: -200px;
  }

  @media (min-width: 1350px) {
    top: -170px;
  }

  @media (min-width: 1500px) {
    top: -200px;
  }
`;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props => (props.isWhite ? '#ffffff' : '#7c7c7c')};
`;

export const FirstBlueLine = styled.div`
  width: 3px;
  height: 170px;
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
  top: -170px;
  left: 76px;

  @media (min-width: 1100px) {
    top: -190px;
    height: 190px;
  }

  @media (min-width: 1350px) {
    top: -170px;
    height: 170px;
  }

  @media (min-width: 1500px) {
    top: -190px;
    height: 190px;
  }
`;

export const SecondBlueLine = styled.div`
  width: 3px;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.blue};
  margin-left: 76px;
`;

export const ThirdBlueLine = styled.div`
  width: 3px;
  height: 320px;
  background-color: ${({ theme }) => theme.colors.blue};
  margin-left: 76px;
`;

export const FirstWhiteLine = styled.div`
  width: 3px;
  height: ${props => (props.isLonger ? '130px' : '100px')};
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 76px;
`;

export const SecondWhiteLine = styled.div`
  width: 3px;
  height: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-left: 76px;
`;

export const FirstHorizonstalLine = styled.div`
  width: 30px;
  height: 3px;
  background-color: ${props => (props.isBlue ? '#8FC1D4' : '#ffffff')};
  position: absolute;
  left: -123px;
  top: 10px;
`;

export const SecondHorizontalLine = styled.div`
  width: 450px;
  height: 3px;
  background-color: ${props => (props.isBlue ? '#8FC1D4' : '#ffffff')};
  position: absolute;
  top: 10px;
  left: 0;

  @media (min-width: 950px) {
    width: 500px;
  }

  @media (min-width: 1050px) {
    width: 600px;
  }

  @media (min-width: 1200px) {
    width: 450px;
  }

  @media (min-width: 1300px) {
    width: 500px;
  }

  @media (min-width: 1450px) {
    width: 550px;
  }

  @media (min-width: 1600px) {
    width: 450px;
  }

  @media (min-width: 1700px) {
    width: 600px;
  }
`;

export const FirstImage = styled(Image)`
  position: absolute;
  top: -40px;
  left: -97px;
`;

export const FirstCircleWrapper = styled.div`
  position: absolute;
  width: 250px;
  height: 254px;
  left: 965px;
  top: 0;
  overflow: hidden;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 75px;
    top: 0;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const SecondCircleWrapper = styled.div`
  position: absolute;
  width: 200px;
  height: 203px;
  bottom: 0;
  left: 1050px;
  overflow: hidden;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 80px;
    bottom: 0;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const ThirdCircleWrapper = styled.div`
  position: absolute;
  width: 200px;
  height: 203px;
  left: 1050px;
  top: 0;
  overflow: hidden;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 80px;
    top: 0;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const FourthCircleWrapper = styled.div`
  position: absolute;
  width: 150px;
  left: 900px;
  top: 50px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 200px;
    top: 100px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const FifthCircleWrapper = styled.div`
  position: absolute;
  width: 300px;
  height: 305px;
  top: 220px;
  left: 1000px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 30px;
    top: 260px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const SixthCircleWrapper = styled.div`
  position: absolute;
  width: 300px;
  height: 305px;
  left: 1000px;
  top: 0;
  overflow: hidden;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 30px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const SeventhCircleWrapper = styled.div`
  position: absolute;
  width: 100px;
  left: 1150px;
  top: 130px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: block;
    left: auto;
    right: 300px;
    top: 100px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

export const EighthCircleWrapper = styled.div`
  position: absolute;
  width: 300px;
  left: 950px;
  top: 250px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (min-width: 1200px) {
    width: 250px;
    display: block;
    left: auto;
    right: 50px;
    top: 150px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;
