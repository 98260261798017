import React from 'react';
import OfferMobile from 'components/offerMobile/offerMobile';
import OfferDesktop from 'components/offerDesktop/offerDesktop';
import { ContentWrapperMobile, ContentWrapper } from './offerContent.styled';

const OfferContent = () => {
  return (
    <>
      <ContentWrapperMobile>
        <OfferMobile />
      </ContentWrapperMobile>
      <ContentWrapper>
        <OfferDesktop />
      </ContentWrapper>
    </>
  );
};

export default OfferContent;
