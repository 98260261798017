import React from 'react';

import Layout from 'components/layout/layout';
import Subscription from 'templates/offerSection/subscription/subscription';
import OfferContent from 'templates/offerSection/offerContent/offerContent';
import FooterMobile from 'components/footerMobile/footerMobile';
import SEO from 'components/SEO/SEO';

const offer = () => (
  <Layout>
    <SEO title="Oferta" />
    <Subscription />
    <OfferContent />
    <FooterMobile />
  </Layout>
);

export default offer;
