import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import styled from 'styled-components';

const Svg = styled.svg`
  width: 300px;
  height: 305px;
  position: absolute;
  top: -160px;
`;

const FourthOfferCircle = () => {
  const circleRef = createRef();

  const tl = gsap.timeline({ repeat: -1 });

  useEffect(() => {
    tl.to(circleRef.current, 50, {
      rotation: 360,
      transformOrigin: 'center center',
      ease: 'none',
    });
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 99.2 100.7"
    >
      <g ref={circleRef}>
        <path
          fill="#FFFFFF"
          d="M16.3,62.5c-6.7-18.5,2.8-39,21.3-45.7s39,2.8,45.7,21.3s-2.8,39-21.3,45.7c-4,1.5-8.1,2.2-12.2,2.2l0,0
		C35.3,86,21.6,77,16.3,62.5 M51.1,3.3c-4.2-0.1-8.4,0.4-12.5,1.4l-1.4-3.8l-9,3.3L29.6,8c-4,1.9-7.6,4.4-10.9,7.3L16.2,13l-6.4,7.1
		l2.6,2.3c-1.7,2.4-3.2,4.9-4.5,7.6c-1.5,3.2-2.6,6.5-3.3,9.9L1,39.5L0,49l3.6,0.4c0,4.1,0.5,8.2,1.6,12.2l-4.5,1.6l3.2,9l4.7-1.7
		c1.9,3.8,4.3,7.3,7.2,10.4l-3.1,3.3l7,6.5l3.2-3.5c2.3,1.6,4.8,3.1,7.4,4.3c1,0.5,2,0.9,3,1.3l-1.1,4.5l9.3,2.3l1.1-4.3
		c4,0.6,8,0.8,12,0.3l1.1,5.1l8.2-1.8l1.1-0.4l-1-4.9c0.6-0.2,1.2-0.4,1.8-0.6c3.4-1.2,6.6-2.9,9.6-4.8l2.8,3.3l7.4-6.1l-2.7-3.2
		c3-3,5.5-6.4,7.6-10.1l4.2,1.8l3.7-8.8l-4.1-1.7c0.9-2.9,1.5-5.8,1.8-8.7l3.2-0.1L99,45l-2.9,0.1c-0.4-3.8-1.2-7.6-2.6-11.3
		c-0.1-0.4-0.3-0.7-0.4-1.1l3.1-1.6l0,0l-4.4-8.5l-3.1,1.6c-2.5-3.8-5.5-7.1-8.9-10l1.6-2.3l-7.9-5.4l-1.6,2.4
		c-0.8-0.4-1.6-0.8-2.4-1.2c-2.9-1.3-5.9-2.4-8.9-3.1L61,1.1l-9.5-1L51.1,3.3z"
        />
        <path
          fill="#FFFFFF"
          d="M55.8,48.4l-2.4-2.1l0,0l-1.8-1.5l-2.5,0.9l0,0c0,0,0,0-0.1,0l-2.7,1L46,48.2l0,0l-0.7,3.9l0.2,0.2l0,0
		l2.7,2.3l0,0l1.3,1.1l2.1-0.8l0,0l3.1-1.1l0.2-1.2l0,0L55.8,48.4L55.8,48.4z M53,51.4l-0.5,0.7l-0.4-0.2c-0.2,0.2-0.5,0.4-0.8,0.5
		l0.1,0.4L50.6,53l-0.1-0.4c-0.3,0-0.7,0-1-0.2l-0.2,0.4l-0.7-0.5l0.2-0.4c-0.2-0.2-0.4-0.5-0.6-0.8l-0.4,0.1l-0.2-0.8l0.4-0.1
		c0-0.3,0-0.7,0.1-1l-0.3-0.2l0.5-0.7l0.3,0.2c0.2-0.3,0.5-0.5,0.8-0.6l-0.1-0.4l0.8-0.2l0.1,0.4c0.3,0,0.7,0,1,0.2l0.2-0.3l0.7,0.5
		l-0.2,0.4c0.2,0.2,0.4,0.5,0.6,0.8l0.4-0.1l0.2,0.8l-0.4,0.1c0,0.3-0.1,0.7-0.2,1L53,51.4z"
        />
      </g>
    </Svg>
  );
};
export default FourthOfferCircle;
