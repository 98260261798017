import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';

export const BackgroundWrapper = styled(BackgroundImage)`
  width: 100%;
  height: auto;
`;

export const OfferWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: auto;
  align-items: center;
  position: relative;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled(Image)`
  margin: 30px 0px;
`;

export const TextSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const TextWrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 0 0 20px 0;
  padding: 0 25px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  display: ${props => (props.isList ? 'flex' : 'block')};
  flex-flow: column;
  align-items: center;
`;

export const TextBox = styled.p`
  width: 100%;
  height: auto;
  color: ${props => (props.isWhite ? `#ffffff` : `#7c7c7c`)};
  font-size: ${({ theme }) => theme.font.s};
`;

export const TextWord = styled.span`
  display: inline-block;
`;

export const Dot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 20px 0;
  background-color: ${props => (props.isWhite ? '#ffffff' : '#7c7c7c')};
`;

export const CircleWrapper = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

export const FirstCircleWrapper = styled.div`
  position: absolute;
  width: 300px;
  top: 400px;
  left: -250px;
`;

export const SecondCircleWrapper = styled.div`
  position: absolute;
  width: 100px;
  top: 400px;
  right: -50px;
`;

export const ThirdCircleWrapper = styled.div`
  position: absolute;
  width: 150px;
  top: 50px;
  right: -70px;
`;

export const FourthCircleWrapper = styled.div`
  position: absolute;
  width: 150px;
  top: 300px;
  left: -100px;
`;

export const FifthCircleWrapper = styled.div`
  position: absolute;
  width: 50px;
  top: 0;
  left: 50px;
  z-index: -1;
`;

export const SixthCircleWrapper = styled.div`
  position: absolute;
  width: 300px;
  top: 60px;
  left: -60px;
  z-index: -1;
`;

export const SeventhCircleWrapper = styled.div`
  position: absolute;
  width: 200px;
  z-index: -1;
  top: 0;
  right: -100px;
`;
