import styled from 'styled-components';

export const Svg = styled.svg`
  width: 331px;
  height: 270px;
  position: absolute;
  right: 40px;

  @media (min-width: 850px) {
    width: 347px;
    right: 50px;
    top: 50px;
  }

  @media (min-width: 1100px) {
    width: 472px;
    top: 100px;
    right: 0;
  }

  .st0 {
    fill: none;
    stroke: #932a85;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .st1 {
    fill: #932a85;
  }
  .st2 {
    fill: none;
  }
  .st3 {
    fill: #ffffff;
  }
  .st5 {
    font-size: 16px;
  }
  .st6 {
    fill: #8fc1d4;
  }
  .st7 {
    fill: #ffffff;
    stroke: #932a85;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: #7c7c7d;
  }
  .st9 {
    enable-background: new 0 0 450.6 367;
  }
`;
