import React, { createRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import gsap from 'gsap';
import { ScrollScene } from 'scrollscene';
import CircleRightSlow from 'components/circle/circleRightSlow';
import CircleRightFast from 'components/circle/circleRightFast';
import CirclePurpleRight from 'components/circle/circlePurpleRight';
import {
  BackgroundWrapper,
  OfferWrapper,
  ImageWrapper,
  StyledImage,
  TextSectionWrapper,
  TitleWrapper,
  TextWrapper,
  TextBox,
  TextWord,
  Dot,
  CircleWrapper,
  FirstCircleWrapper,
  SecondCircleWrapper,
  ThirdCircleWrapper,
  FourthCircleWrapper,
  FifthCircleWrapper,
  SixthCircleWrapper,
  SeventhCircleWrapper,
} from './offerMobile.styled';

const OfferMobile = () => {
  const {
    background,
    firstImage,
    secondImage,
    thirdImage,
  } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstImage: file(relativePath: { eq: "offerFirst.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      secondImage: file(relativePath: { eq: "offerSecond.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      thirdImage: file(relativePath: { eq: "offerThird.png" }) {
        sharp: childImageSharp {
          fixed(quality: 100, width: 105, height: 105) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const firstImageRef = createRef();
  const secondImageRef = createRef();
  const thirdImageRef = createRef();

  const firstTitleRef = createRef();
  const secondTitleRef = createRef();
  const thirdTitleRef = createRef();

  const firstTextRef = createRef();
  const secondTextRef = createRef();
  const thirdTextRef = createRef();

  const firstTextListRef = createRef();
  const secondTextListRef = createRef();
  const thirdTextListRef = createRef();

  const tl = gsap.timeline();

  const firstSectionTl = gsap.timeline({ paused: true });

  const secondSectionTl = gsap.timeline({ paused: true });

  const thirdSectionTl = gsap.timeline({ paused: true });

  useEffect(() => {
    tl.set(
      [
        firstImageRef.current,
        firstTitleRef.current,
        firstTextRef.current,
        firstTextListRef.current,
        secondImageRef.current,
        secondTitleRef.current,
        secondTextRef.current,
        secondTextListRef.current,
        thirdImageRef.current,
        thirdTitleRef.current,
        thirdTextRef.current,
        thirdTextListRef.current,
      ],
      { opacity: 0 }
    );
    firstSectionTl
      .fromTo(
        firstImageRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set([firstTitleRef.current, firstTextRef.current], { opacity: 1 })
      .addLabel('startTextAndTitle')
      .staggerFrom(
        firstTitleRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .staggerFrom(
        firstTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .set(firstTextListRef.current, { opacity: 1 })
      .fromTo(
        firstTextListRef.current.children[0],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[1],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[2],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[3],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[4],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[5],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[6],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[7],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[8],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[9],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[10],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[11],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[12],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[13],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[14],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[15],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        firstTextListRef.current.children[16],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        firstTextListRef.current.children[17],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      );
    secondSectionTl
      .fromTo(
        secondImageRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set([secondTitleRef.current, secondTextRef.current], { opacity: 1 })
      .addLabel('startTextAndTitle')
      .staggerFrom(
        secondTitleRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .staggerFrom(
        secondTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .set(secondTextListRef.current, { opacity: 1 })
      .fromTo(
        secondTextListRef.current.children[0],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        secondTextListRef.current.children[1],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        secondTextListRef.current.children[2],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        secondTextListRef.current.children[3],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      )
      .fromTo(
        secondTextListRef.current.children[4],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        secondTextListRef.current.children[5],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      );
    thirdSectionTl
      .fromTo(
        thirdImageRef.current,
        0.25,
        { opacity: 1, scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .set([thirdTitleRef.current, thirdTextRef.current], { opacity: 1 })
      .addLabel('startTextAndTitle')
      .staggerFrom(
        thirdTitleRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .staggerFrom(
        thirdTextRef.current.children,
        0.5,
        { opacity: 0, x: -20, y: -20 },
        0.25,
        'startTextAndTitle'
      )
      .set(thirdTextListRef.current, { opacity: 1 })
      .fromTo(
        thirdTextListRef.current.children[0],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        thirdTextListRef.current.children[1],
        0.5,
        { opacity: 0, x: 50 },
        0.25
      )
      .fromTo(
        thirdTextListRef.current.children[2],
        0.25,
        { scale: 0, transformOrigin: 'center center' },
        { scale: 1, transformOrigin: 'center center' }
      )
      .staggerFrom(
        thirdTextListRef.current.children[3],
        0.5,
        { opacity: 0, x: -50 },
        0.25
      );
    const firstScrollScene = new ScrollScene({
      triggerElement: firstImageRef.current,
      reverse: false,
      gsap: {
        timeline: firstSectionTl,
      },
    });
    const secondScrollScene = new ScrollScene({
      triggerElement: secondImageRef.current,
      reverse: false,
      gsap: {
        timeline: secondSectionTl,
      },
    });
    const thirdScrollScene = new ScrollScene({
      triggerElement: thirdImageRef.current,
      reverse: false,
      gsap: {
        timeline: thirdSectionTl,
      },
    });
  });

  return (
    <>
      <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
        <OfferWrapper>
          <ImageWrapper ref={firstImageRef}>
            <StyledImage fixed={firstImage.sharp.fixed} />
          </ImageWrapper>
          <TextSectionWrapper>
            <TitleWrapper>
              <TextBox isWhite ref={firstTitleRef}>
                <TextWord>Usługi&nbsp;</TextWord>
                <TextWord>graficzne&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>animacje</TextWord>
              </TextBox>
            </TitleWrapper>
            <TextWrapper>
              <TextBox isWhite ref={firstTextRef}>
                <TextWord>W&nbsp;</TextWord>
                <TextWord>swojej&nbsp;</TextWord>
                <TextWord>pracy&nbsp;</TextWord>
                <TextWord>wykorzystujemy&nbsp;</TextWord>
                <TextWord>najnowsze&nbsp;</TextWord>
                <TextWord>trendy&nbsp;</TextWord>
                <TextWord>w&nbsp;</TextWord>
                <TextWord>projektowaniu&nbsp;</TextWord>
                <TextWord>graficznym&nbsp;</TextWord>
                <TextWord>co&nbsp;</TextWord>
                <TextWord>pozwala&nbsp;</TextWord>
                <TextWord>nam&nbsp;</TextWord>
                <TextWord>z&nbsp;</TextWord>
                <TextWord>łatwością&nbsp;</TextWord>
                <TextWord>dostosować&nbsp;</TextWord>
                <TextWord>się&nbsp;</TextWord>
                <TextWord>do&nbsp;</TextWord>
                <TextWord>każdego&nbsp;</TextWord>
                <TextWord>zadania.</TextWord>
              </TextBox>
            </TextWrapper>
            <TextWrapper isList ref={firstTextListRef}>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>projektowanie logo</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>grafika komputerowa</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>grafika 2D i grafika 3D</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>grafika rastrowa</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>grafika wektorowa</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>animacje</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>prezentacje</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>plakaty</TextWord>
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                <TextWord>identyfikacja wizualna</TextWord>
              </TextBox>
            </TextWrapper>
          </TextSectionWrapper>
          <FirstCircleWrapper>
            <CircleRightSlow />
          </FirstCircleWrapper>
          <SecondCircleWrapper>
            <CircleRightFast />
          </SecondCircleWrapper>
        </OfferWrapper>
      </BackgroundWrapper>
      <OfferWrapper>
        <ImageWrapper ref={secondImageRef}>
          <StyledImage fixed={secondImage.sharp.fixed} />
        </ImageWrapper>
        <TextSectionWrapper>
          <TitleWrapper>
            <TextBox ref={secondTitleRef}>
              <TextWord>Social&nbsp;</TextWord>
              <TextWord>Media</TextWord>
            </TextBox>
          </TitleWrapper>
          <TextWrapper>
            <TextBox ref={secondTextRef}>
              <TextWord>Działalność&nbsp;</TextWord>
              <TextWord>w&nbsp;</TextWord>
              <TextWord>social&nbsp;</TextWord>
              <TextWord>mediach&nbsp;</TextWord>
              <TextWord>to&nbsp;</TextWord>
              <TextWord>podstawa&nbsp;</TextWord>
              <TextWord>-&nbsp;</TextWord>
              <TextWord>jeśli&nbsp;</TextWord>
              <TextWord>chcesz&nbsp;</TextWord>
              <TextWord>zaistnieć&nbsp;</TextWord>
              <TextWord>w&nbsp;</TextWord>
              <TextWord>sieci&nbsp;</TextWord>
              <TextWord>i&nbsp;</TextWord>
              <TextWord>zwiększyć&nbsp;</TextWord>
              <TextWord>liczbę&nbsp;</TextWord>
              <TextWord>swoich&nbsp;</TextWord>
              <TextWord>odbiorców&nbsp;</TextWord>
              <TextWord>to&nbsp;</TextWord>
              <TextWord>idealnie&nbsp;</TextWord>
              <TextWord>trafiłeś.</TextWord>
            </TextBox>
          </TextWrapper>
          <TextWrapper isList ref={secondTextListRef}>
            <Dot />
            <TextBox>
              <TextWord>
                dobór odpowiednich <br />
                kanałów komunikacyjncych
              </TextWord>
            </TextBox>
            <Dot />
            <TextBox>
              <TextWord>
                reklama w portalach społecznościowych <br />
                tworzenie treści, pulikacji
              </TextWord>
            </TextBox>
            <Dot />
            <TextBox>
              <TextWord>monitorowanie i analizowanie mediów</TextWord>
            </TextBox>
          </TextWrapper>
        </TextSectionWrapper>
        <ThirdCircleWrapper>
          <CirclePurpleRight />
        </ThirdCircleWrapper>
        <FourthCircleWrapper>
          <CirclePurpleRight />
        </FourthCircleWrapper>
      </OfferWrapper>
      <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
        <OfferWrapper>
          <ImageWrapper ref={thirdImageRef}>
            <StyledImage fixed={thirdImage.sharp.fixed} />
          </ImageWrapper>
          <TextSectionWrapper>
            <TitleWrapper>
              <TextBox isWhite ref={thirdTitleRef}>
                <TextWord>Strony&nbsp;</TextWord>
                <TextWord>internetowe&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>sklepy&nbsp;</TextWord>
                <TextWord>internetowe</TextWord>
              </TextBox>
            </TitleWrapper>
            <TextWrapper>
              <TextBox isWhite ref={thirdTextRef}>
                <TextWord>Pomożemy&nbsp;</TextWord>
                <TextWord>stworzyć&nbsp;</TextWord>
                <TextWord>Ci&nbsp;</TextWord>
                <TextWord>unikatową&nbsp;</TextWord>
                <TextWord>stronę&nbsp;</TextWord>
                <TextWord>internetową&nbsp;</TextWord>
                <TextWord>-&nbsp;</TextWord>
                <TextWord>wyróżnij&nbsp;</TextWord>
                <TextWord>się&nbsp;</TextWord>
                <TextWord>wśród&nbsp;</TextWord>
                <TextWord>konkurencji&nbsp;</TextWord>
                <TextWord>i&nbsp;</TextWord>
                <TextWord>pozyskaj&nbsp;</TextWord>
                <TextWord>nowych&nbsp;</TextWord>
                <TextWord>klientów.</TextWord>
              </TextBox>
            </TextWrapper>
            <TextWrapper isList ref={thirdTextListRef}>
              <Dot isWhite />
              <TextBox isWhite>
                tworzenie i poprawa stron www i sklepów internetowych
              </TextBox>
              <Dot isWhite />
              <TextBox isWhite>
                zamieszczanie tekstów oraz aktualizacja treści
              </TextBox>
            </TextWrapper>
          </TextSectionWrapper>
          <CircleWrapper>
            <FifthCircleWrapper>
              <CircleRightFast />
            </FifthCircleWrapper>
            <SixthCircleWrapper>
              <CircleRightSlow />
            </SixthCircleWrapper>
            <SeventhCircleWrapper>
              <CircleRightSlow />
            </SeventhCircleWrapper>
          </CircleWrapper>
        </OfferWrapper>
      </BackgroundWrapper>
    </>
  );
};

export default OfferMobile;
